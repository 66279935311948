.shepherd-element,
.shepherd-header {
    border-radius: 12px;
}

.shepherd-header {
    background: #FFFFFF !important;
    padding: 32px 32px 8px 32px !important;
    color: #242628 !important; /*NEUTRAL_10*/
}

.shepherd-text {
    padding: 0 32px 24px 32px !important;
    color: #7B7F85 !important; /*NEUTRAL_7*/
}

.shepherd-footer {
    border-top: #C1C7D1 1px solid;
    padding: 24px;
}

.shepherd-button {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    line-height: 28px;
    padding: 1px 10px 1px 10px !important;
    height: 32px;
    border-radius: 6px;
}

.shepherd-button-primary {
    color: #fff !important;
    background-color: #1890FF; /*HIGHLIGHT_PRIMARY*/
}

.shepherd-button-primary:hover {
    background-color: #0077E5 !important; /*HIGHLIGHT_PRIMARY_HOVER*/
}

.shepherd-button-primary:active {
    background-color: #065EAF !important; /*HIGHLIGHT_PRIMARY_ACTIVE*/
}

.shepherd-button-secondary {
    background: none !important;
    margin-right: 8px !important;
}

.shepherd-button-secondary:hover {
    background-color: #F7F8F9 !important; /*NEUTRAL_3*/
}

.shepherd-button-secondary:active {
    background-color: #EEF0F2 !important; /*NEUTRAL_4*/
}

.shepherd-progress {
    color: #6F7783; /*VidappSecondary*/
    font-size: 14px;
    line-height: 27px;
    font-weight: 400;

    width: 100%;
    padding-left: 8px;
}

.shepherd-modal-overlay-container.shepherd-modal-is-visible {
    opacity: 0.3 !important;
}

.shepherd-active-button:disabled {
    background-color: #1890FF !important;
    border-color: #1890FF !important;
}

.shepherd-hover .customize-button-wrapper {
    display: block !important;    
}
.shepherd-hover .anticon {
    opacity: 1 !important;
}