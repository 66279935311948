.react-app.react-container, #login-panel {
  height: 100%;

  @import '~antd/dist/antd.less';
  @import '~antd/es/calendar/style';
  @import '~antd/es/date-picker/style';

  @font-family: 'Inter', sans-serif;
  @primary-color: #1890FF;
  @text-color-secondary: #6e6e6e;
  @error-color: #FF4D4F;

  .ant-tooltip-arrow {
    display: none;
  }

  .ant-tooltip-arrow-content {
    box-shadow: 3px 3px 7px rgb(0 0 0 / 7%);
    transform: translateY(-6px) rotate(45deg);
  }


  // Remove table header separators
  .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):before {
    background-color: transparent !important;
  }

  // Rotate Menu group arrow on open/close
  .ant-menu-submenu-inline .ant-menu-submenu-arrow::before {
    transform: rotate(-45deg) translateX(2.5px);
  }

  .ant-menu-submenu-inline .ant-menu-submenu-arrow::after {
    transform: rotate(45deg) translateX(-2.5px);
  }

  .ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before {
    transform: rotate(45deg) translateX(2.5px);
  }

  .ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
    transform: rotate(-45deg) translateX(-2.5px);
  }

  .ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
    transform: translateY(-2px);
  }

  .ant-drawer {
    z-index: 0; // Prevent drawer from displaying over modal background overlay
  }

  .ant-drawer-header {
    border-bottom: none;
    border-left: 1px solid #E0E3E6;
    padding: 28px;
  }

  .ant-drawer-content {
    overflow: visible !important;
  }

  .ant-drawer-right > .ant-drawer-content-wrapper {
    top: 0;
    right: 0;
    bottom: 0;
  }

  // Custom styles for AntD's Modal component
  .ant-modal-content {
    border: 1px solid #E0E3E6;
    border-radius: 8px;

    .ant-modal-body {
      padding: 28px;

      .ant-modal-confirm-body {
        display: flex;
        flex-direction: column;
        align-items: center;

        .anticon {
          margin-right: 0;
          margin-bottom: 16px;
          font-size: 35px;
        }

        .ant-modal-confirm-title {
          font-size: 16px;
          line-height: 24px;
          color: #3D3F42;
        }

        .ant-modal-confirm-content {
          font-size: 14px;
          line-height: 20px;
          color: #7B7F85;
          margin-left: 0 !important;
          margin-top: 4px;
          text-align: center;

          p {
            margin-bottom: 0;
          }
        }
      }

      .ant-modal-confirm-btns {
        margin-top: 28px;

        button {
          min-width: 150px;
          border-radius: 6px;
        }
      }
    }
  }

  .ant-modal-confirm {
    .ant-modal-confirm-btns {
      text-align: center;
    }
  }

  .ant-modal-mask {
    background-color: rgba(2, 7, 20, 0.45);
  }
}
